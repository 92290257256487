import axios from 'axios'
import Router from 'next/router'

export const setFormErrorsParser = () =>
  axios.interceptors.response.use(undefined, (error) => {
    if (error?.response?.status === 400 && error?.response?.data) {
      return Promise.reject(error?.response.data)
    }

    if (error?.response?.status === 401) {
      // redirect if token expired or not present
      Router.push('/login')
    }

    return Promise.reject(error)
  })
