import axios from 'axios'
import useSWR from 'swr'

const fetcher = (url) => axios.get(url)

export default function useManager(managerId) {
  const url = `${process.env.NEXT_PUBLIC_API_MANAGERS}/${managerId}`
  const { data, error } = useSWR(managerId ? url : null, fetcher)

  return {
    manager: data?.data,
    isLoading: !error && !data,
    isError: error,
  }
}
