import React, { useEffect, useState, useMemo } from 'react'
import Head from 'next/head'
import axios from 'axios'
import { appWithTranslation } from 'next-i18next'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { SnackbarProvider } from 'notistack'
import { setFormErrorsParser } from '../core/axios-interceptors'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import FoodbankContext from '@contexts/foodbank-context'
import UserContext from '@contexts/user-context'
import '../../styles/globals.css'
import createEmotionCache from 'src/create-emotion-cache'
import { CacheProvider } from '@emotion/react'
import theme from '../../theme'
import * as locales from '@mui/material/locale'
import { hotjar } from 'react-hotjar'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import 'dayjs/locale/ru'
import useManager from '@hooks/use-manager'
import { useRouter } from 'next/router'
import { USER_ROLES } from '@utils/constants'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

axios.defaults.withCredentials = true

setFormErrorsParser()

const localesMapping = {
  ru: 'ruRU',
  es: 'esES',
  en: 'enUS',
}

export const FoodbankApp = (props) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props
  const [foodbank, setFoodbank] = useState()
  const [user, setUser] = useState()
  const { locale } = props.router

  useEffect(() => {
    hotjar.initialize(2845951, 6)
  }, [])

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [])

  useEffect(() => {
    if (dayjs.locale() !== locale) {
      dayjs.locale(locale)
    }
  }, [locale])

  const themeWithLocale = useMemo(
    () => createTheme(theme, locales[localesMapping[locale]]),
    [locale, theme]
  )

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <meta name="theme-color" content="#FB9203" />
      </Head>
      <ThemeProvider theme={themeWithLocale}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <SnackbarProvider
            maxSnack={3}
            autoHideDuration={3000}
            variant="success"
          >
            <UserContext.Provider value={{ user, setUser }}>
              <FoodbankContext.Provider value={{ foodbank, setFoodbank }}>
                <Component {...pageProps} />
              </FoodbankContext.Provider>
            </UserContext.Provider>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </CacheProvider>
  )
}

export default appWithTranslation(FoodbankApp)
