import { createTheme } from '@mui/material/styles'

// TODO use the new theme format without the adapter for backward compatibility with version 4
const theme = {
  palette: {
    primary: {
      main: '#03638C',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#9BDFFD',
      contrastText: '#024664',
    },
    default: {
      main: '#FFFFFF',
      contrastText: '#03638C',
    },
    sidebar: {
      main: '#024664',
      contrastText: '#FFFFFF',
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        backgroundColor: '#FFFFFF',
        borderRadius: '8px',
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: '#FFFFFF',
        borderRadius: '8px',
      },
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto,Helvetica,Arial,sans-serif;',
        },
      },
    },
  },
  shadows: [
    'none',
    '2px 3px 10px rgba(0, 0, 0, 0.04), 2px 2px 3px rgba(0, 0, 0, 0.020)',
    ...Array(25).fill(
      '2px 3px 10px rgba(0, 0, 0, 0.05), 2px 2px 3px rgba(0, 0, 0, 0.025)'
    ),
  ],
  shape: {
    borderRadius: '8px',
  },
  typography: {
    fontFamily:
      'Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;',
    button: {
      fontWeight: '400',
      fontSize: 'inherit',
      textTransform: 'none',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
}

export default theme
